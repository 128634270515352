<template>
    <section id="wrapper">
        <div class="login-register">
            <div class="login-box card">
                <div class="card-body">
                    <div v-if="!isApiConfirm">
                        <form class="form-horizontal" @submit.stop.prevent="apiKeyConfirm">
                            <h3 class="box-title m-b-20 bold text-center text-uppercase">Xác Nhận API Key</h3>
                            <div class="form-group ">
                                <div class="col-xs-12">
                                    <input
                                        class="form-control"
                                        v-model="apiKey"
                                        type="text"
                                        required=""
                                        placeholder="Nhập API key"
                                    />
                                </div>
                            </div>
                            <div class="form-group text-center">
                                <div class="col-xs-12">
                                    <button
                                        class="btn btn-info btn-rounded text-uppercase waves-effect waves-light"
                                        type="submit"
                                    >
                                        Xác nhận
                                    </button>
                                </div>
                            </div>
                            <div class="form-group m-b-0">
                                <div class="col-sm-12 text-center">
                                    <span class="text-muted"
                                        >Chưa có API Key? Vui lòng liên hệ Quản Trị Viên để được hướng dẫn</span
                                    >
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- <div v-else>
                        <form class="form-horizontal form-material" @submit.stop.prevent="passwordUpdate">
                            <h3 class="box-title m-b-20 text-center">Tạo Tài Khoản Admin</h3>
                            <div class="form-group ">
                                <div class="col-xs-12">
                                    <label>Email:</label>
                                    <input class="form-control" v-model="email" type="text" required="" disabled />
                                </div>
                            </div>
                            <div class="form-group ">
                                <div class="col-xs-12">
                                    <label>Username Admin</label>
                                    <input class="form-control" v-model="username" type="text" required="" placeholder="Tài khoản quản trị viên" />
                                </div>
                            </div>
                            <div class="form-group ">
                                <div class="col-xs-12">
                                    <input class="form-control" v-model="password" type="password" required="" placeholder="Mật khẩu" />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-xs-12">
                                    <input class="form-control" v-model="password_confirm" type="password" required="" placeholder="Nhập lại mật khẩu" />
                                </div>
                            </div>
                            <div class="form-group text-center mb-0">
                                <div class="col-xs-12">
                                    <button :class="['btn btn-info btn-rounded text-uppercase waves-effect waves-light']" type="submit">Cập Nhật</button>
                                </div>
                            </div>
                        </form>
                    </div> -->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { verifyApiKey, userUpdateInfo } from "../../api/user"
import { catchError } from "../../helpers"

export default {
    name: "api-key",
    data() {
        return {
            userId: 0,
            apiKey: "",
            username: "",
            email: "",
            password: "",
            password_confirm: "",
            isApiConfirm: false
        }
    },
    computed: {
        site() {
            return this.$store.state.site.site
        },
        isNewSite() {
            return this.site == null
        }
    },
    methods: {
        validEmail: function(email) {
            var re = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return re.test(email)
        },
        apiKeyConfirm: async function() {
            let siteUrl = "http://" + document.location.host
            siteUrl = siteUrl.replace("http://www.", "http://")
            let postData = {
                domain: siteUrl,
                api_key: this.apiKey
            }
            let data = await verifyApiKey(postData)
            if (data.success && data.status === 200) {
                this.isApiConfirm = true
                this.$store.commit("STORE_SET_TOKEN", data.token)
                this.$store.commit("STORE_SET_USER", data.data)
                this.userId = data.data.id
                this.username = data.data.username
                this.email = data.data.email
                await this.$store.dispatch("GET_SITE", document.location.host)
                if (!data.update) {
                    this.$swal("Thành công", data.message, "success")
                    this.$store.dispatch("LOGOUT")
                }
            } else {
                this.$swal("Lỗi", catchError(data), "error")
            }
        },
        passwordUpdate: async function() {
            if (this.password !== this.password_confirm) {
                this.$toastr.error("Mật khẩu và mật khẩu xác nhận không giống nhau. Vui lòng thử lại")
                return
            }
            let postData = {
                username: this.username,
                old_password: "12345678",
                password: this.password
            }
            let data = await userUpdateInfo(this.userId, postData)
            if (data.status === 200 && data.success) {
                this.$swal("Thành Công", "Cập nhật thành công, vui lòng đăng nhập để tiếp tục", "success")
                let siteHost = document.location.host
                await this.$store.dispatch("GET_SITE", siteHost)
                this.$store.dispatch("LOGOUT")
            } else {
                this.$swal("Lỗi", catchError(data), "error")
            }
        }
    }
}
</script>

<style>
.login-register {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 100%;
    width: 100%;
    padding: 10% 0;
    position: fixed;
}

.login-box {
    width: 555px;
    margin: 0 auto;
}
.login-box .footer {
    width: 100%;
    left: 0px;
    right: 0px;
}
.login-box .social {
    display: block;
    margin-bottom: 30px;
}

#recoverform {
    display: none;
}

.login-sidebar {
    padding: 0px;
    margin-top: 0px;
}
.login-sidebar .login-box {
    right: 0px;
    position: absolute;
    height: 100%;
}

/*-- ==============================================================
 Phone and below ipad(767px)
 ============================================================== */
@media (max-width: 767px) {
    .container-fluid {
        padding: 0px 15px 0px 15px;
        overflow: hidden;
    }
    /*Header*/
    .topbar {
        width: 100%;
    }
    .topbar .top-navbar {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        -webkit-align-items: center;
    }
    .topbar .top-navbar .navbar-collapse {
        display: flex;
        width: 100%;
    }
    .topbar .top-navbar .navbar-nav {
        flex-direction: row;
    }
    .topbar .top-navbar .navbar-nav > .nav-item.show {
        position: static;
    }
    .topbar .top-navbar .navbar-nav > .nav-item.show .dropdown-menu {
        width: 100%;
        margin-top: 0px;
    }
    .topbar .top-navbar .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .mega-dropdown .dropdown-menu {
        height: 480px;
        overflow: auto;
    }
    .comment-text .comment-footer .action-icons {
        display: block;
        padding: 10px 0;
    }
    .vtabs .tabs-vertical {
        width: auto;
    }
    /*Footer*/
    .material-icon-list-demo .icons div {
        width: 100%;
    }
    .error-page .footer {
        position: fixed;
        bottom: 0px;
        z-index: 10;
    }
    .error-box {
        position: relative;
        padding-bottom: 60px;
    }
    .error-body {
        padding-top: 10%;
    }
    .error-body h1 {
        font-size: 100px;
        font-weight: 600;
        line-height: 100px;
    }
    .login-register {
        position: relative;
        overflow: hidden;
    }
    .login-box {
        width: 90%;
    }
    .login-sidebar {
        padding: 10% 0;
    }
    .login-sidebar .login-box {
        position: relative;
    }
    /*This is for chat page*/
    .chat-main-box .chat-left-aside {
        left: -250px;
        position: absolute;
        transition: 0.5s ease-in;
        background: #fff;
    }
    .chat-main-box .chat-left-aside.open-pnl {
        left: 0px;
    }
    .chat-main-box .chat-left-aside .open-panel {
        display: block;
    }
    .chat-main-box .chat-right-aside {
        width: 100%;
    }
    /*Timeline*/
    ul.timeline:before {
        left: 40px;
    }
    ul.timeline > li > .timeline-panel {
        width: calc(100% - 90px);
    }
    ul.timeline > li > .timeline-badge {
        top: 16px;
        left: 15px;
        margin-left: 0;
    }
    ul.timeline > li > .timeline-panel {
        float: right;
    }
    ul.timeline > li > .timeline-panel:before {
        right: auto;
        left: -15px;
        border-right-width: 15px;
        border-left-width: 0;
    }
    ul.timeline > li > .timeline-panel:after {
        right: auto;
        left: -14px;
        border-right-width: 14px;
        border-left-width: 0;
    }
    /*Contact app page*/
    .left-aside {
        width: 100%;
        position: relative;
        border: 0px;
    }
    .right-aside {
        margin-left: 0px;
    }
    .flex-wrap {
        flex-wrap: wrap !important;
        -webkit-flex-wrap: wrap !important;
    }
    .chat-list li .chat-content {
        width: calc(100% - 80px);
    }
    /*Calendar*/
    .fc-toolbar .fc-left,
    .fc-toolbar .fc-right,
    .fc-toolbar .fc-center {
        float: none;
        display: block;
        text-align: center;
        margin-top: 10px;
        overflow: hidden;
    }
    .hidden-md-down {
        display: none;
    }
}
</style>
